<template>
  <!-- Customers filters  -->
  <div id="p1s4c3" class="p-3 overflow-y-scroll hide-scrollbar">
    <h1 class="text-lg font-bold text-center">Filters</h1>

    <div class="grid grid-cols-6 gap-6 items-center">

      <!-- Search area -->
      <label class="col-span-1">Keyword:
        <span class="tooltip">
          ?
          <span class="tooltiptext whitespace-pre-line">
            Search by Order Number, Description, Card Holder Name, etc.
          </span>
        </span>
      </label>
      <SearchBar class="col-span-2" containerClasses="w-full" inputClasses="w-full" v-model="DV_searchText"/>
      
      <!-- Entries Per Page filter -->
      <div class="justify-center col-span-2 text-center grid grid-cols-3" style="display:flex; align-items:center;">
        <label class="font-medium text-sm" for="entrierPerPage" style="margin-right:10px;">Show</label>
        <select 
          style="width:auto; margin-right:10px;" 
          id="entriesPerPage" 
          name="entriesPerPage" 
          v-model="DV_selectedNumberOfEntriesPerPage"
          @change="selectEntriesPerPageFilter($event.target.value)"
        >
          <option v-for="entries in DV_numberOfEntriesPerPage">
            {{entries}}
          </option>
        </select>
        <label class="font-medium text-sm">Entries Per Page</label>
      </div>
      
      <!-- Payment Status filter -->
      <div class="text-center items-center mb-5">
        <label class="font-medium text-sm" for="paymentStatus">Status</label>
        <select 
          id="paymentStatus" 
          name="paymentStatus" 
          class="dropdown-select" 
          v-model="DV_selectedPaymentStatusFilter"
          @change="selectPaymentStatusFilter($event.target.value)"
        >
          <option v-for="status in paymentStatusList">
            {{status}}
          </option>
        </select>
      </div>

      <!-- Date range filter -->
      <label class="font-medium text-sm" for="startDate">Start Date:</label>
        <input 
          type="date"
          style="width:auto;" 
          :max="DV_dateToday" 
          id="startDate" 
          v-model="DV_startDate" 
          @change="selectStartDateFilter($event.target.value)"
        >

        <label class="font-medium text-sm" for="endDate">End Date:</label>
        <input 
          type="date" 
          style="width:auto;"
          :min="DV_startDate" 
          :max="DV_dateToday" 
          id="endDate" 
          v-model="DV_endDate" 
          @change="selectEndDateFilter($event.target.value)"
        >
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import SearchBar from "../shared/SearchBar.vue";
import moment from 'moment';

export default {
  name: "p1s4c3PaymentLinkFilters",

  props: ["paymentStatusList"],

  components: {SearchBar},

  data() {
    return {
      DV_searchText: "",
      DV_numberOfEntriesPerPage: [10, 25, 50, 100],
      DV_startDate: moment().format("YYYY-MM-DD"),
      DV_endDate: moment().format("YYYY-MM-DD"),
      DV_dateToday: moment().format("YYYY-MM-DD"),
      DV_selectedPaymentStatusFilter: "All",
      DV_selectedNumberOfEntriesPerPage: 10,
      moment: moment,
    };
  },

  mounted() {
    // load cached data
    const cachedPaymentStatusFilter = window.localStorage.getItem("p1s4c3_payment_status_filter") || "";
    const cachedEntriesPerPageFilter = window.localStorage.getItem("p1s4c3_entries_perpage_filter") || "";
    const cachedStartDateFilter = window.localStorage.getItem("p1s4c3_start_date_filter") || "";
    const cachedEndDateFilter = window.localStorage.getItem("p1s4c3_end_date_filter") || "";
    
    if (cachedPaymentStatusFilter) {
      this.DV_selectedPaymentStatusFilter = cachedPaymentStatusFilter;
      this.$emit("event-payment-status-filter-selected", cachedPaymentStatusFilter);
    }

    if (cachedEntriesPerPageFilter) {
      this.DV_selectedNumberOfEntriesPerPage = cachedEntriesPerPageFilter;
      this.$emit("event-entries-per-page-filter-selected", cachedEntriesPerPageFilter)
    }

    if (cachedStartDateFilter) {
      this.DV_startDate = cachedStartDateFilter;
      this.$emit("event-start-date-filter-selected", cachedStartDateFilter);
    }

    if (cachedEndDateFilter) {
      this.DV_endDate = cachedEndDateFilter;
      this.$emit("event-end-date-filter-selected", cachedEndDateFilter);
    }
  },

  methods: {
    selectPaymentStatusFilter(filter) {
      window.localStorage.setItem("p1s4c3_payment_status_filter", filter)
      this.$emit("event-payment-status-filter-selected", filter)
    },

    selectEntriesPerPageFilter(filter) {
      window.localStorage.setItem("p1s4c3_entries_perpage_filter", filter)
      this.$emit("event-entries-per-page-filter-selected", filter)
    },

    selectStartDateFilter(filter) {
      window.localStorage.setItem("p1s4c3_start_date_filter", filter)
      this.$emit("event-start-date-filter-selected", filter)
    },
    
    selectEndDateFilter(filter) {
      window.localStorage.setItem("p1s4c3_end_date_filter", filter)
      this.$emit("event-end-date-filter-selected", filter)
    },
  },

  watch: {
    DV_searchText: {
      handler() {
        this.$emit("event-search-filter-selected", this.DV_searchText)
      }
    },
  }
};
</script>